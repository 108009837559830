import type * as Types from './types';

import {
  useQuery,
  type QueryClient,
  type FetchQueryOptions,
  type UseQueryOptions,
} from '@tanstack/react-query';
import type { DXError } from '@dx-ui/types-graphql';

export const GetSmbMultiBusinessToggleDocument = {
  operationName: 'featureToggles',
  operationString: `query featureToggles {
  featureToggles(names: ["smbMultiBusiness"]) {
    enabled
    name
  }
}`,
  originalOpName: 'getSmbMultiBusinessToggle',
};
export function useGetSmbMultiBusinessToggleQuery<
  TData = Types.GetSmbMultiBusinessToggleQuery,
  TError = DXError
>(
  variables?: Types.GetSmbMultiBusinessToggleQueryVariables,
  options?: Omit<UseQueryOptions<Types.GetSmbMultiBusinessToggleQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.GetSmbMultiBusinessToggleQuery, TError, TData>({
    queryKey: [GetSmbMultiBusinessToggleDocument, variables],
    ...options,
  });
}
export function serverSideGetSmbMultiBusinessToggleQuery(
  queryClient: QueryClient,
  variables?: Types.GetSmbMultiBusinessToggleQueryVariables,
  options?: FetchQueryOptions<Types.GetSmbMultiBusinessToggleQuery>
) {
  return queryClient.fetchQuery<Types.GetSmbMultiBusinessToggleQuery>({
    queryKey: [GetSmbMultiBusinessToggleDocument, variables],
    ...options,
  });
}
