import { Trans, useTranslation } from 'next-i18next';
import { useAuth } from '@dx-ui/framework-auth-provider';
import type { SMBContextType } from '@dx-ui/utilities-use-smb-context-local-storage';
import {
  useSMBContextLocalStorage,
  useSMBProgramIdLocalStorage,
} from '@dx-ui/utilities-use-smb-context-local-storage';
import cx from 'classnames';
import { useIsClient } from 'usehooks-ts';
import set from 'lodash/set';
import { SMBMultiBusinessModal } from './osc-smb-multi-business-modal';
import { useSMBMultiBusinessToggle } from './hooks/useSmbMultiBusinessToggle';
import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';

type SMBProfileSwitcherProps = {
  isSmbChecked: boolean;
  onInputChange?: () => void;
  onChangeBusiness?: () => void;
} & React.HTMLAttributes<HTMLDivElement>;

/**
 * SMB Profiler Switcher. Reads localStorage context value `smbContext` for initial state. Toggle subscribes to localStorage as it may be updated by other logic (session timeout, etc)
 *
 * @param props
 */
export function SMBProfileSwitcher({
  className,
  onInputChange,
  isSmbChecked,
  onChangeBusiness,
}: SMBProfileSwitcherProps) {
  const { t, ready: isTranslationNSReady } = useTranslation('osc-smb-profile-switcher');
  const { smbContext, setSMBContext } = useSMBContextLocalStorage(true);
  const { guestInfo, isLoading } = useAuth();
  const isClient = useIsClient();
  const { isLoading: toggleDataLoading, smbMultiBusinessEnabled } = useSMBMultiBusinessToggle();
  const { isSMBProgramIdSet, smbProgramId, setSMBProgramId } = useSMBProgramIdLocalStorage();
  const isInBusinessContext = smbContext === 'business';
  const hasMultipleBusinesses = (guestInfo?.hhonors?.programAccountSummary?.length || 0) > 1;
  const shouldShowMultiBusinessOption = smbMultiBusinessEnabled && hasMultipleBusinesses;
  const isInBusinessButNoProgramIdSet = isInBusinessContext && !isSMBProgramIdSet;
  const travelForBusinessChecked = shouldShowMultiBusinessOption
    ? isInBusinessContext && isSMBProgramIdSet
    : isInBusinessContext;
  const showChangeBusinessBtn =
    shouldShowMultiBusinessOption && isInBusinessContext && isSMBProgramIdSet;
  const { query } = useRouter();

  const [modalState, setModalState] = useState<{
    isVisible: boolean;
    onAccept: ((selectedProgramId: number | null) => void) | undefined;
    onCancel: (() => void) | undefined;
  }>({ isVisible: false, onAccept: undefined, onCancel: undefined });

  async function focusOnCTA({ ctaId }: { ctaId: string }) {
    const ctaElement: Promise<HTMLElement> = new Promise((resolve) => {
      const intervalId = window.setInterval(() => {
        const elem = document.querySelector(`#${ctaId}`);
        if (elem) {
          clearInterval(intervalId);
          resolve(elem as HTMLElement);
        }
      }, 50);
    });
    await ctaElement.then((elm) => elm.focus());
  }

  const hideMultiBusinessModal = () =>
    setModalState({ onAccept: undefined, onCancel: undefined, isVisible: false });

  const handleChangeBusiness = () =>
    Promise.resolve()
      .then(() => onChangeBusiness?.())
      .then((callback?: (() => void) | void) => {
        setModalState({
          isVisible: true,
          onAccept: (selectedProgramId) => {
            hideMultiBusinessModal();
            void focusOnCTA({ ctaId: 'changeBusinessCTA' });
            if (selectedProgramId !== smbProgramId) {
              callback?.();
            }
          },
          onCancel: () => {
            hideMultiBusinessModal();
          },
        });
      })
      .catch(console.error); // eslint-disable-line no-console

  const handleInputChange = async () => {
    const nextContext = smbContext === 'personal' ? 'business' : 'personal';
    const switchingToBusiness = nextContext === 'business';
    const shouldShowMultiBusinessModal = shouldShowMultiBusinessOption && switchingToBusiness;
    await Promise.resolve()
      .then(() => {
        if (shouldShowMultiBusinessModal) {
          return new Promise((resolve, reject) => {
            setModalState({
              isVisible: true,
              onAccept: () => {
                hideMultiBusinessModal();
                resolve({});
              },
              onCancel: () => {
                hideMultiBusinessModal();
                // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
                reject();
              },
            });
          });
        }
      })
      .then(() => onInputChange?.())
      .then((callback?: ((context: SMBContextType) => void) | void) => {
        if (window?.digitalData && window?._satellite) {
          set(
            window.digitalData,
            'user[0].profile[0].attributes.profileStatus',
            switchingToBusiness ? 'business_travel' : nextContext
          );
          set(window.digitalData, 'click.clickID', 'profile_switch_btn');
          window._satellite?.track?.('global_click');
        } else {
          console.warn('digitalData or _satellite not found'); // eslint-disable-line no-console
        }
        callback?.(nextContext);
        setSMBContext(nextContext);
        if (nextContext === 'personal' && isSMBProgramIdSet) {
          setSMBProgramId(null);
        }
        void focusOnCTA({ ctaId: 'profileSwitch' });
      })
      .catch((err) => {
        console.error(err); // eslint-disable-line no-console
      });
  };
  const firstBusinessName = guestInfo?.hhonors?.programAccountSummary[0]?.accountName || '';
  const selectedBusinessName =
    guestInfo?.hhonors?.programAccountSummary.find(
      (programAccount) => programAccount.accountId === smbProgramId
    )?.accountName || '';
  const businessName = shouldShowMultiBusinessOption ? selectedBusinessName : firstBusinessName;
  const SMBContext = travelForBusinessChecked ? businessName : t('personalTravel');
  const initializedMultiBusinessModal = useRef(false);
  const programAccountIdParam = isNaN(Number(query.programAccountId))
    ? undefined
    : Number(query.programAccountId);
  const hasSetProgramId = useRef(false);

  useEffect(() => {
    if (
      shouldShowMultiBusinessOption &&
      isInBusinessButNoProgramIdSet &&
      isSmbChecked &&
      !initializedMultiBusinessModal.current
    ) {
      initializedMultiBusinessModal.current = true;
      setModalState({
        isVisible: true,
        onAccept: () => {
          hideMultiBusinessModal();
        },
        onCancel: async () => {
          hideMultiBusinessModal();
          await Promise.resolve()
            .then(() => onInputChange?.())
            .then((callback?: ((context: SMBContextType) => void) | void) => {
              const url = new URL(window.location.href);
              url.searchParams.delete('smbRate');
              const newUrl = url.toString();
              window.history.replaceState(
                { ...window.history.state, as: newUrl, url: newUrl },
                '',
                newUrl
              );
              callback?.('personal');
              setSMBContext('personal');
            })
            .catch((err) => {
              console.error(err); // eslint-disable-line no-console
            });
        },
      });
    }
  }, [
    isInBusinessButNoProgramIdSet,
    onInputChange,
    setSMBContext,
    shouldShowMultiBusinessOption,
    isSmbChecked,
  ]);

  useEffect(() => {
    if (programAccountIdParam) {
      if (shouldShowMultiBusinessOption && !hasSetProgramId.current) {
        hasSetProgramId.current = true;
        if (!isInBusinessContext) {
          setSMBContext('business');
        }
        setSMBProgramId(programAccountIdParam);
      }
      const url = new URL(window.location.href);
      url.searchParams.delete('programAccountId');
      const newUrl = url.toString();
      window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, '', newUrl);
    }
  }, [
    isInBusinessContext,
    programAccountIdParam,
    setSMBContext,
    setSMBProgramId,
    shouldShowMultiBusinessOption,
  ]);

  if (
    isLoading ||
    !isClient ||
    !smbContext ||
    !guestInfo?.hhonors?.isSMBMember ||
    !isTranslationNSReady ||
    toggleDataLoading
  )
    return null;

  return (
    <>
      <div className={cx('bg-bg-alt flex flex-col flex-wrap items-start px-4 py-2.5', className)}>
        <label className="flex cursor-pointer items-center text-lg font-bold">
          <span className="first-letter:capitalize">{t('businessTravel')}</span>
          <div className="relative mx-2 inline-flex">
            <input
              id="profileSwitch"
              aria-describedby="currentlyBookingFor"
              className="peer sr-only"
              type="checkbox"
              onChange={handleInputChange}
              checked={travelForBusinessChecked}
            />
            <div className="peer-checked:bg-hilton peer-focus:ring-hilton peer-checked:after:border-bg after:border-bg-disabled after:bg-bg peer h-6 w-12 rounded-full bg-[#878787] ring-offset-1 after:absolute after:top-[2px] after:size-5 after:rounded-full after:border after:transition-all after:content-[''] peer-focus:outline-none peer-focus:ring-2 motion-reduce:transition-none ltr:after:left-[4px] peer-checked:ltr:after:translate-x-full rtl:after:right-[4px] peer-checked:rtl:after:-translate-x-full" />
          </div>
        </label>
        <div className="flex text-sm">
          <span className="relative self-center">
            <span id="currentlyBookingFor" aria-hidden="true">
              <Trans
                t={t}
                i18nKey="youAreCurrentlyBookingFor"
                values={{ SMBContext }}
                components={{ b: <strong /> }}
              />
              {travelForBusinessChecked ? (
                <span>&nbsp;– {t('businessTravelInfoMsg')}&nbsp;</span>
              ) : null}
            </span>
            {showChangeBusinessBtn ? (
              <button
                id="changeBusinessCTA"
                aria-describedby="currentlyBookingFor"
                className="btn-primary-link"
                type="button"
                onClick={handleChangeBusiness}
              >
                {t('changeBusiness')}
              </button>
            ) : null}
          </span>
        </div>
      </div>
      {modalState.isVisible ? (
        <SMBMultiBusinessModal onAccept={modalState.onAccept} onCancel={modalState.onCancel} />
      ) : null}
    </>
  );
}

export type { SMBProfileSwitcherProps };
export default SMBProfileSwitcher;
