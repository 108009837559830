import { getIsFeatureToggleEnabled } from '@dx-ui/utilities-feature-toggles-configs';
import { useGetSmbMultiBusinessToggleQuery } from '../gql/queries';

export function useSMBMultiBusinessToggle() {
  const { data, isLoading } = useGetSmbMultiBusinessToggleQuery();

  return {
    isLoading,
    smbMultiBusinessEnabled: getIsFeatureToggleEnabled({
      featureToggleData: data,
      name: 'smbMultiBusiness',
    }),
  };
}
