import { useTranslation } from 'next-i18next';
import { Dialog } from '@dx-ui/osc-dialog';
import { useSMBProgramIdLocalStorage } from '@dx-ui/utilities-use-smb-context-local-storage';
import { useAuth } from '@dx-ui/framework-auth-provider';
import { useState } from 'react';

/**
 * SMB Multi-Business modal. Displays multiple businesses from programAccountSummary and sets localStorage value `smbProgramId` for selected program.
 *
 * Use the `<SMBMultiBusinessModal/>` component inside of the `SMBMultiBusinessModalProvider` in order to show the multi-business modal
 * to shop for select business.
 * @param props
 */
export function SMBMultiBusinessModal({
  onAccept,
  onCancel,
}: {
  onAccept?: (selectedProgramId: number | null) => void;
  onCancel?: () => void;
}) {
  const { t, ready } = useTranslation('osc-smb-profile-switcher');
  const { smbProgramId, setSMBProgramId } = useSMBProgramIdLocalStorage();
  const { guestInfo, isLoading } = useAuth();
  const programAccountSummary = guestInfo?.hhonors?.programAccountSummary || [];
  const firstAccountId = guestInfo?.hhonors?.programAccountSummary?.[0]?.accountId || null;
  const preselectedBusiness = smbProgramId ?? firstAccountId;
  const [selectedProgramId, setSelectedProgramId] = useState(preselectedBusiness);

  function handleCancel() {
    setSelectedProgramId(preselectedBusiness);
    onCancel?.();
  }

  if (isLoading || !ready) {
    return null;
  }

  const handleAccept = () => {
    setSMBProgramId(selectedProgramId);
    onAccept?.(selectedProgramId);
  };

  return (
    <Dialog
      ariaLabel={t('multiBusinessModal.header')}
      title={t('multiBusinessModal.header')}
      size="sm"
      onDismiss={handleCancel}
    >
      <fieldset>
        <legend className="sr-only">{t('multiBusinessModal.header')}</legend>
        {programAccountSummary.map((programAccount, index) => {
          const accountName = programAccount.accountName || '';
          const businessOption = `businessOption${index + 1}`;
          return (
            <label key={programAccount.accountName} className="flex items-center py-2 font-bold">
              <input
                type="radio"
                name="businessOption"
                value={businessOption}
                checked={programAccount.accountId === selectedProgramId}
                onChange={() =>
                  programAccount.accountId && setSelectedProgramId(programAccount.accountId)
                }
                className="form-radio me-2 cursor-pointer self-center sm:self-auto"
              />
              {accountName}
            </label>
          );
        })}
      </fieldset>
      <div className="mt-3">
        <button
          type="button"
          className="btn btn-xl btn-primary w-full whitespace-normal"
          onClick={handleAccept}
        >
          {t('multiBusinessModal.ok')}
        </button>
        <button
          type="button"
          className="btn btn-xl btn-primary-outline mt-2 w-full whitespace-normal"
          onClick={handleCancel}
        >
          {t('multiBusinessModal.cancel')}
        </button>
      </div>
    </Dialog>
  );
}
